import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/accessible-icon.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/alert-dialog.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/aspect-ratio.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/avatar.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/callout.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/checkbox.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/context-menu.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/dialog.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/dropdown-menu.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/hover-card.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/popover.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/portal.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/radio-group.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/scroll-area.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/select.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/separator.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/slider.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/switch.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/tabs.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/text-field.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/tooltip.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/components/visually-hidden.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/theme-panel.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/dist/esm/theme.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@radix-ui/themes/styles.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.js\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-loading-skeleton/dist/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-loading-skeleton/dist/skeleton.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/BrowserSupportDialog/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ClientInitializations/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ForceUpdateApp/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/FraudDialog/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/ManualBanDialog/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/OfflineDialog/index.js")